import { Content, Heading } from "react-bulma-components";
import React from "react";

//import styles from "./InforCard.module.scss";

// font-awesome has the following icons we can use for each primary mover
//  - fa-wind-turbine
//  - fa-solar-panel
//  - fa-water
//  - fa-tank-water
//  - fa-fire
//  - fa-atom
//  - fa-gas-pump
//  - fa-oil-can
//  - fa-battery-bolt
//  - fa-bolt
//  - fa-biohazard

/*

prime_mover_code from EIA 860M

BA Energy Storage, Battery
BT Turbines Used in a Binary Cycle. Including those used for geothermal applications
CA Combined-Cycle -- Steam Part
CE Energy Storage, Compressed Air
CP Energy Storage, Concentrated Solar Power
CS Combined-Cycle Single-Shaft Combustion Turbine and Steam Turbine share of single generator
CT Combined-Cycle Combustion Turbine Part
ES Energy Storage, Other (Specify on Schedule 9, Comments)
FC Fuel Cell
FW Energy Storage, Flywheel
GT Combustion (Gas) Turbine. Including Jet Engine design
HA Hydrokinetic, Axial Flow Turbine
HB Hydrokinetic, Wave Buoy
HK Hydrokinetic, Other
HY Hydraulic Turbine. Including turbines associated with delivery of water by pipeline.
IC Internal Combustion (diesel, piston, reciprocating) Engine
PS Energy Storage, Reversible Hydraulic Turbine (Pumped Storage)
OT Other
ST Steam Turbine. Including Nuclear, Geothermal, and Solar Steam (does not include Combined Cycle).
PV Photovoltaic
WT Wind Turbine, Onshore
WS Wind Turbine, Offshore


our dataset has the following counts for each prime_mover_code

 prime_mover_code | count 
------------------+-------
 CC               |     1
 FW               |     1
 CP               |     2
 OT               |     6
 FC               |     6
 CS               |    20
 PS               |    24
 BT               |    24
 BA               |   101
 CA               |   135
 CT               |   233
 IC               |   273
 ST               |   443
 GT               |   597
 HY               |   663
 PV               |   770
 WT               |   902
(17 rows)


our dataset has the following counts for each technology
                 technology                  | count 
---------------------------------------------+-------
 Flywheels                                   |     1
 Coal Integrated Gasification Combined Cycle |     1
 Petroleum Coke                              |     2
 Solar Thermal with Energy Storage           |     2
 Other Natural Gas                           |     6
 Solar Thermal without Energy Storage        |     8
 Other Gases                                 |     9
 All Other                                   |    16
 Other Waste Biomass                         |    17
 Hydroelectric Pumped Storage                |    24
 Geothermal                                  |    35
 Nuclear                                     |    36
 Municipal Solid Waste                       |    38
 Natural Gas Internal Combustion Engine      |    71
 Wood/Wood Waste Biomass                     |    73
 Natural Gas Steam Turbine                   |   100
 Batteries                                   |   101
 Landfill Gas                                |   101
 Conventional Steam Coal                     |   149
 Petroleum Liquids                           |   224
 Natural Gas Fired Combined Cycle            |   382
 Natural Gas Fired Combustion Turbine        |   470
 Conventional Hydroelectric                  |   663
 Solar Photovoltaic                          |   770
 Onshore Wind Turbine                        |   902
(25 rows)

our dataset has the following min, max and average capacity for all power plants
rtobots=# select avg(capacity), min(capacity), max(capacity) from power_plant;
        avg        | min | max  
-------------------+-----+------
 98.90754582242323 | 0.2 | 1440
(1 row)

We can make a scale from ~0.2 to ~1440 MW for a gradient scale
*/

export function PowerPlantCard({
  properties,
}: {
  properties;
}): React.ReactElement {
  const icons = {
    BA: "fa-battery-full",
    BT: "fa-heat" /* pro icon */,
    CA: "fa-fire-flame-simple" /* fa-fire-flame */,
    CC: "fa-fire-flame-simple",
    CE: "fa-spray-can-sparkles",
    CP: "fa-sun",
    CS: "fa-fire-flame-simple",
    CT: "fa-fire-flame-simple",
    FC: "fa-battery-bolt",
    FW: "fa-tire",
    GT: "fa-fire-flame-simple",
    HA: "fa-water",
    HB: "fa-water",
    HK: "fa-water",
    HY: "fa-water",
    IC: "fa-fire-flame-simple",
    OT: "fa-bolt",
    ST: "fa-heat" /* pro icon */,
    PV: "fa-solar-panel",
    WT: "fa-wind-turbine" /* pro icon ... */,
    WS: "fa-wind-turbine" /* search here instead https://icons8.com/icons/set/wind-turbine */,
  };
  return (
    <Content>
      <Heading size={6}>
        {properties && properties.prime_mover_code ? (
          <i
            aria-hidden="true"
            className={
              "fa-solid " + icons[properties.prime_mover_code] + "fa-bolt"
            }
          />
        ) : (
          <i aria-hidden="true" className={"fa-solid fa-bolt"} />
        )}
        &nbsp;
        {properties?.name ?? "N/A"}
      </Heading>
      <p>
        {properties?.capacity ?? "N/A"} MW
        <br />
        {properties?.technology}
        <br />
        {properties?.prime_mover_code}
        <br />
        {properties?.status}
      </p>
    </Content>
  );
}

export default PowerPlantCard;
