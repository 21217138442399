import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import rootReducer from "../reducers/root";
import { loginFlow, logoutFlow, refreshFlow, logActions } from "../auth/sagas";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  // TODO: re-install redux devtools, I removed the package because it was out of date
  const composeEnhancers =
    (typeof window !== "undefined" &&
      window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]) ||
    compose;

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware), applyMiddleware(thunk))
  );

  sagaMiddleware.run(loginFlow);
  sagaMiddleware.run(logoutFlow);
  sagaMiddleware.run(refreshFlow);
  sagaMiddleware.run(logActions);

  return store;
}
