function Copyright(): React.ReactElement {
  return (
    <>
      <p>
        {"Copyright © Enertel AI "}
        {new Date().getFullYear()}
      </p>
    </>
  );
}

export default Copyright;
