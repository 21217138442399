import { Outlet, useLocation, useNavigate } from "react-router-dom";
import TopBar from "./TopBar";
import { Section } from "react-bulma-components";
import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useAccessToken } from "@/hooks";

const AppLayout = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = useAccessToken();

  const [, setReturnTo] = useLocalStorage("returnTo", "/app/dashboard");

  useEffect(() => {
    if (!accessToken) {
      setReturnTo(location.pathname);
      navigate("/login");
    }
  }, [location.pathname, accessToken, navigate, setReturnTo]);

  // Can't render the app without an access token, all of the requests will fail
  if (!accessToken) {
    return <>Loading...</>;
  }

  return (
    <>
      <TopBar />
      <Section paddingless>
        <Outlet />
      </Section>
    </>
  );
};

export default AppLayout;
