interface LogoProps {
  [key: string]: string | number;
}

const Logo = (props: LogoProps): React.ReactElement => {
  return (
    <img alt="Logo" src="/static/images/logo_big.png" height={40} {...props} />
  );
};

export default Logo;
