import { useState } from "react";
import { Text, Paper, Checkbox, Grid, Col, ActionIcon } from "@mantine/core";
import { IconChevronUp, IconCircuitBattery } from "@tabler/icons-react";

import styles from "./ControlPanel.module.scss";

export type Layer = {
  name: string;
  id: string;
  value: string;
};

export type ControlPanelProps = {
  layers: Layer[];
  hiddenLayers: Set<string>;
  setHiddenLayers: (layers: Set<string>) => void;
};

function ControlPanel({
  layers,
  hiddenLayers,
  setHiddenLayers,
}: ControlPanelProps) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Paper
      className={styles.ControlPanel}
      style={{ overflow: "hidden", transition: "max-height 0.3s" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <Text align="left" size="l">
          Grid Objects
        </Text>
        <ActionIcon onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? (
            <IconCircuitBattery size={24} />
          ) : (
            <IconChevronUp size={24} />
          )}
        </ActionIcon>
      </div>

      {!collapsed && (
        <div style={{ padding: "10px" }}>
          {layers.map((layer) => (
            <Grid gutter="xs" key={layer.id}>
              <Col
                span={1}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Checkbox
                  id={layer.id}
                  checked={!hiddenLayers.has(layer.id)}
                  onChange={() => {
                    const set = new Set(hiddenLayers);
                    if (hiddenLayers.has(layer.id)) {
                      set.delete(layer.id);
                    } else {
                      set.add(layer.id);
                    }
                    setHiddenLayers(set);
                  }}
                />
              </Col>
              <Col span={9}>
                <Text>{layer.name}</Text>
              </Col>
            </Grid>
          ))}
        </div>
      )}
    </Paper>
  );
}

export default ControlPanel;
