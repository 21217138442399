import axios from "axios";
import {
  createContext,
  useContext,
  useEffect,
  ReactNode,
  ReactElement,
} from "react";
import useAccessToken from "@/hooks/useAccessToken";

type AuthContextType = {
  token: string | null;
};

interface IAuthProvider {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextType>({ token: null });
AuthContext.displayName = "AuthContext";

export const useAuthContext = (): AuthContextType => useContext(AuthContext);

export const AuthProvider = ({ children }: IAuthProvider): ReactElement => {
  const token = useAccessToken();

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ token }}>{children}</AuthContext.Provider>
  );
};
