import axios from "axios";
import React from "react";
import { useState } from "react";
import {
  Block,
  Button,
  Form,
  Icon,
  Message,
  Modal,
} from "react-bulma-components";

interface CreateApiTokenModalProps {
  show: boolean;
  onClose: () => void;
}

type CreateApiTokenModalState = "NEW" | "SUCCESS" | "ERROR";

export function CreateApiTokenModal({
  show,
  onClose,
}: CreateApiTokenModalProps): React.ReactElement {
  const [description, setDescription] = useState("");
  const [expiresAt, setExpiresAt] = useState<string | null>(null);
  const [secret, setSecret] = useState<string | null>(null);
  const [state, setState] = useState<CreateApiTokenModalState>("NEW");
  const [error, setError] = useState<string | null>(null);

  const copySecret = () => {
    navigator.clipboard.writeText(secret ?? "");
  };

  const onCreate = () => {
    const submit = async () => {
      if (description === "") {
        setError("The description field is required.");
        return;
      }
      const params = {
        description,
      };
      if (expiresAt !== "" && expiresAt != null) {
        params["expires_at"] = expiresAt;
      }
      const response = await axios.post("/api/api_token", params);
      if (response.status >= 400) {
        setState("ERROR");
        setSecret("");
      } else {
        setSecret(response.data?.secret);
        setState("SUCCESS");
      }
    };
    submit();
  };

  const onDismiss = () => {
    setSecret(null);
    setExpiresAt(null);
    setDescription("");
    setState("NEW");
    onClose();
  };

  return (
    <Modal show={show} onClose={onDismiss}>
      <Modal.Card>
        {state === "NEW" && (
          <>
            <Modal.Card.Header>
              <Modal.Card.Title>Create new API token</Modal.Card.Title>
            </Modal.Card.Header>
            <Modal.Card.Body>
              <Message>
                <Message.Body>
                  Fill out the form to create a new API token
                </Message.Body>
              </Message>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Description</Form.Label>
                  <Form.Input
                    value={description}
                    onChange={(e) => setDescription(e.currentTarget.value)}
                    required
                  />
                </Form.Control>
              </Form.Field>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Expiry Date (optional)</Form.Label>
                  <Form.Input
                    type="date"
                    value={expiresAt}
                    onChange={(e) => setExpiresAt(e.currentTarget.value)}
                  />
                </Form.Control>
              </Form.Field>
              {error && <Message color="danger">{error}</Message>}
              <Button
                outlined
                color="success"
                onClick={() => {
                  onCreate();
                }}
              >
                Create
              </Button>
            </Modal.Card.Body>
          </>
        )}
        {state === "SUCCESS" && (
          <>
            <Modal.Card.Header>
              <Modal.Card.Title>Create new API token</Modal.Card.Title>
            </Modal.Card.Header>
            <Modal.Card.Body>
              <Message color="success">
                <Message.Header>Your new API token is ready</Message.Header>
                <Message.Body>
                  <Block color="info" flexDirection="column">
                    <p>
                      Copy the token below and store it in a safe place. You
                      will not be able to see it again. Use this token in your
                      API requests, in the <code>Authorization</code> header.
                    </p>
                  </Block>
                  <Block>
                    <Form.Textarea textFamily={"code"} rows={5} readOnly>
                      {secret}
                    </Form.Textarea>
                  </Block>
                  <Block>
                    <Button onClick={() => copySecret()} outlined color="info">
                      <Icon onClick={() => copySecret()} mr={1}>
                        <i className="fa fa-clipboard"></i>
                      </Icon>
                      Copy to Clipboard
                    </Button>
                  </Block>
                </Message.Body>
              </Message>
            </Modal.Card.Body>
            <Modal.Card.Footer>
              <Button onClick={() => onDismiss()}>Ok</Button>
            </Modal.Card.Footer>
          </>
        )}
        {state === "ERROR" && (
          <>
            <Message color="danger">
              <Message.Header>Error creating API token</Message.Header>
              <Message.Body>
                An error occurred while creating your API token. Please try
                again later.
              </Message.Body>
              <Modal.Card.Footer>
                <Button onClick={() => onDismiss()}>Ok</Button>
              </Modal.Card.Footer>
            </Message>
          </>
        )}
      </Modal.Card>
    </Modal>
  );
}

export default CreateApiTokenModal;
