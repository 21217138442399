import axios from "axios";
import React from "react";
import { useState } from "react";
import {
  Block,
  Box,
  Button,
  Form,
  Heading,
  Icon,
  Message,
} from "react-bulma-components";

export function ChangePassword(): React.ReactElement {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const onSubmit = () => {
    const submit = async () => {
      setMessage("");
      setError("");

      if (newPassword != confirmPassword) {
        setError("New password and confirm password do not match");
        return;
      }

      const response = await axios.post("/api/change_password", {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });
      if (response.status == 200) {
        setMessage("Password changed successfully");
      } else {
        setError("Error changing password. " + response.data?.error);
      }
    };
    submit();
  };

  return (
    <Box>
      <Block>
        <Heading size={5}>Change Password</Heading>
        <Form.Field>
          <Form.Label>Current Password</Form.Label>
          <Form.Control>
            <Form.Input
              type="password"
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.currentTarget?.value)}
            />
            <Icon align="left" size="small">
              <i className="fas fa-lock" />
            </Icon>
            <Icon align="right" size="small">
              <i className="fas fa-check" />
            </Icon>
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>New Password</Form.Label>
          <Form.Control>
            <Form.Input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.currentTarget?.value)}
            />
            <Icon align="left" size="small">
              <i className="fas fa-lock" />
            </Icon>
            <Icon align="right" size="small">
              <i className="fas fa-check" />
            </Icon>
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control>
            <Form.Input
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.currentTarget?.value)}
            />
            <Icon align="left" size="small">
              <i className="fas fa-lock" />
            </Icon>
            <Icon align="right" size="small">
              <i className="fas fa-check" />
            </Icon>
          </Form.Control>
        </Form.Field>
        <Block>
          {error !== "" && <Message color="danger">{error}</Message>}
          {message !== "" && <Message color="primary">{message}</Message>}
          <Button color="success" outlined onClick={() => onSubmit()}>
            Change Password
          </Button>
        </Block>
      </Block>
    </Box>
  );
}
