import { GeoJsonFeature, GeoJsonFeatureCollection, GridObject } from "./types";

export function toGeoJson(gridObjects: GridObject[]): GeoJsonFeatureCollection {
  const features: GeoJsonFeature[] = [];
  for (const gridObject of gridObjects ?? []) {
    features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [gridObject.longitude, gridObject.latitude],
      },
      properties: gridObject,
    });
  }
  return { type: "FeatureCollection", features };
}

export function parseIfString(value) {
  if (typeof value === "string") {
    try {
      return JSON.parse(value);
    } catch (error) {
      console.error("Failed to parse the string:", error);
      return value; // return original string if it's not a valid JSON
    }
  }
  return value;
}
