export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const AUTO_LOGIN = "AUTO_LOGIN";
export const LOGOUT = "LOGOUT";
export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const REFRESH_ERROR = "REFRESH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_CANCELLED = "LOGIN_CANCELLED";
export const SAVE_AUTH_TOKEN = "SAVE_AUTH_TOKEN";
export const DELETE_AUTH_TOKEN = "DELETE_AUTH_TOKEN";
export const REFRESH_AUTH_TOKEN = "REFRESH_AUTH_TOKEN";

export default {
  AUTO_LOGIN,
  LOGIN_REQUEST,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_CANCELLED,
  SAVE_AUTH_TOKEN,
  DELETE_AUTH_TOKEN,
  REFRESH_AUTH_TOKEN,
  REFRESH_SUCCESS,
  REFRESH_ERROR,
};
