export const ANONYMOUS = "Anonymous";
export const AUTO_LOGIN_ATTEMPT = "Auto login";

export const LOGGED_IN = "Logged in";
export const LOGGED_OUT = "Logged out";
export const LOGIN_ERROR = "Login error";
export const LOGIN_CANCELLED = "Login cancelled";

export type LoginState =
  | "Anonymous"
  | "Auto login"
  | "Logged in"
  | "Logged out"
  | "Login error"
  | "Login cancelled";
