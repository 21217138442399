import { useEffect, useState } from "react";
import ReactMapGL, {
  MapLayerMouseEvent,
  Source,
  Layer,
  Popup,
} from "react-map-gl";

import { Container, Text, Paper } from "@mantine/core";

import maplibregl from "maplibre-gl";
import { useWindowSize } from "usehooks-ts";
import { useGridObjects } from "@/api/grid";
import ControlPanel from "./components/ControlPanel/ControlPanel";
import { GeoJsonFeatureCollection } from "./types";
import { TargetFeature } from "@/types";
import React from "react";
import { toGeoJson } from "./utilities";
import { PowerPlantCard } from "./components/InfoCard/PowerPlantCard";
import { useMe } from "@/api/auth";

function GridMap(): React.ReactElement {
  const { width, height } = useWindowSize();

  const { data: user } = useMe();

  /* GridObjects */
  const [powerPlants, setPowerPlants] = useState<GeoJsonFeatureCollection>();
  const [priceNodes, setPriceNodes] = useState<GeoJsonFeatureCollection>();
  const [ISOs, setISOs] = useState<GeoJsonFeatureCollection>();
  const [loadZones, setLoadZones] = useState<GeoJsonFeatureCollection>();
  const [weatherStations, setWeatherStations] =
    useState<GeoJsonFeatureCollection>();
  const [gasHubs, setGasHubs] = useState<GeoJsonFeatureCollection>();

  /* Targets */
  const [hoveredTarget, setHoveredTarget] = useState<TargetFeature | null>(
    null
  );

  /* GeoJson */
  const [layer, setLayer] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [bbox] = useState([24.9493, -125.0011, 49.5904, -66.9326]);
  const [viewState, setViewState] = useState({
    longitude: -85,
    latitude: 37,
    zoom: 3.5,
  });

  /* Control Panel */
  const layers =
    user && user.admin
      ? [
          { name: "Power Plants", id: "powerPlants", value: "power_plant" },
          { name: "Price Nodes", id: "priceNodes", value: "price_node" },
          {
            name: "Weather Stations",
            id: "weatherStations",
            value: "weather_station",
          },
        ]
      : [
          { name: "Power Plants", id: "powerPlants", value: "power_plant" },
          {
            name: "Weather Stations",
            id: "weatherStations",
            value: "weather_station",
          },
        ];
  const [hiddenLayers, setHiddenLayers] = useState(
    new Set([
      "powerPlants",
      "priceNodes",
      "weatherStations",
      "gasHubs",
    ] as string[])
  );

  const ObjectTypes: string[] = layers.map((obj) => obj.value);

  /* Grid Objects */
  const {
    data: gridObjectData,
    loading: gridObjectLoading,
    error: gridObjectError,
  } = useGridObjects(ObjectTypes, bbox as [number, number, number, number]);

  useEffect(() => {
    if (!gridObjectLoading && !gridObjectError && gridObjectData) {
      setPowerPlants(toGeoJson(gridObjectData.power_plant));
      setPriceNodes(toGeoJson(gridObjectData.price_node));
      setISOs(toGeoJson(gridObjectData.iso));
      setLoadZones(toGeoJson(gridObjectData.load_zone));
      setWeatherStations(toGeoJson(gridObjectData.weather_station));
      setGasHubs(toGeoJson(gridObjectData.gas_hub));
    }
  }, [gridObjectLoading, gridObjectError, gridObjectData]);

  /* Map */
  const handleMouseMove = (event: MapLayerMouseEvent) => {
    const features = event.target.queryRenderedFeatures(event.point);
    const hoveredFeature =
      features.find(
        (feature) =>
          feature.layer?.id === "powerPlants" ||
          feature.layer?.id === "priceNodes" ||
          feature.layer?.id === "geoTargets"
      ) || null;

    if (hoveredFeature) {
      event.target.getCanvas().style.cursor = "pointer";
      setLayer(hoveredFeature.layer.id);
      // @ts-ignore
      setHoveredTarget(hoveredFeature.properties);
      setShowPopup(true);
    } else {
      event.target.getCanvas().style.cursor = "";
      setShowPopup(false);
    }
  };

  /* Styles */

  return (
    <>
      <ReactMapGL
        minZoom={3.5}
        maxZoom={15}
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        mapLib={maplibregl}
        style={{ height, width }}
        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
        onMouseMove={handleMouseMove}
      >
        {!hiddenLayers.has("powerPlants") && powerPlants && (
          <Source id="powerPlants" type="geojson" data={powerPlants}>
            <Layer
              id="powerPlants"
              type="circle"
              minzoom={3}
              paint={{
                "circle-radius": 5,
                "circle-opacity": 0.5,
                "circle-color": "#f00",
                "circle-stroke-width": 1,
                "circle-stroke-color": "#fff",
              }}
            />
          </Source>
        )}
        {!hiddenLayers.has("priceNodes") && priceNodes && (
          <Source id="priceNodes" type="geojson" data={priceNodes}>
            <Layer
              id="priceNodes"
              type="circle"
              minzoom={3}
              paint={{
                "circle-radius": 5,
                "circle-opacity": 0.5,
                "circle-color": "#0f0",
                "circle-stroke-width": 1,
                "circle-stroke-color": "#fff",
              }}
            />
          </Source>
        )}

        {!hiddenLayers.has("loadZones") && loadZones && (
          <Source id="loadZones" type="geojson" data={loadZones}>
            <Layer id="loadZones" type="fill" minzoom={3} paint={{}} />
          </Source>
        )}
        {!hiddenLayers.has("weatherStations") && weatherStations && (
          <Source id="weatherStations" type="geojson" data={weatherStations}>
            <Layer id="weatherStations" type="circle" minzoom={3} paint={{}} />
          </Source>
        )}
        {!hiddenLayers.has("gasHubs") && gasHubs && (
          <Source id="gasHubs" type="geojson" data={gasHubs}>
            <Layer id="gasHubs" type="circle" minzoom={3} paint={{}} />
          </Source>
        )}
        {ISOs && (
          <Source id="ISOs" type="geojson" data={ISOs}>
            <Layer
              id="isos"
              type="symbol"
              minzoom={3}
              paint={{ "text-color": "#BEBEBE" }}
              layout={{
                "text-size": 33,
                "text-field": "{name}",
              }}
            />
          </Source>
        )}

        {showPopup && layer && (
          <Popup
            longitude={hoveredTarget?.longitude ?? 100}
            latitude={hoveredTarget?.latitude ?? 40}
            onClose={() => setShowPopup(false)}
            style={{ zIndex: 1000 }}
          >
            {layer === "geoTargets" ? (
              <Paper style={{ maxWidth: "300px" }}>
                <Container size="md">
                  <Text align="left" size="md" style={{ marginBottom: "10px" }}>
                    <i aria-hidden="true" className={"fa-solid fa-landmark"} />
                    &nbsp;
                    {hoveredTarget?.object_name}
                  </Text>
                  <Text align="left" size="sm" style={{ marginBottom: "10px" }}>
                    {hoveredTarget?.iso} {hoveredTarget?.series_name}{" "}
                    {hoveredTarget?.n_steps}
                    {hoveredTarget?.freq}
                  </Text>
                </Container>
              </Paper>
            ) : (
              <PowerPlantCard properties={hoveredTarget} />
            )}
          </Popup>
        )}
      </ReactMapGL>
      <ControlPanel
        layers={layers}
        setHiddenLayers={setHiddenLayers}
        hiddenLayers={hiddenLayers}
      />
    </>
  );
}

export default GridMap;
