import actionTypes from "./actions";
import {
  ANONYMOUS,
  AUTO_LOGIN_ATTEMPT,
  LOGGED_IN,
  LOGGED_OUT,
  LoginState,
  LOGIN_CANCELLED,
  LOGIN_ERROR,
} from "./states";
import produce from "immer";

export interface LoginStateType {
  token: null | string;
  tokenExpiresAt: null | Date;
  status: LoginState;
}

const INITIAL_STATE: LoginStateType = {
  token: null,
  tokenExpiresAt: null,
  status: ANONYMOUS,
};

const getTokenExpiry = (token: string): Date => {
  const encPayload = token.split(".")[1];
  const payload = JSON.parse(atob(encPayload));
  return new Date(payload["exp"] * 1000);
};

const loginReducer = produce((state, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      state.status = LOGGED_IN;
      break;
    case actionTypes.AUTO_LOGIN:
      state.status = AUTO_LOGIN_ATTEMPT;
      break;
    case actionTypes.REFRESH_SUCCESS:
      state.status = LOGGED_IN;
      break;
    case actionTypes.REFRESH_ERROR:
      state.token = null;
      state.tokenExpiresAt = null;
      state.status = LOGGED_OUT;
      break;
    case actionTypes.SAVE_AUTH_TOKEN:
      state.token = action.token;
      state.tokenExpiresAt = getTokenExpiry(action.token);
      break;
    case actionTypes.DELETE_AUTH_TOKEN:
      state.token = null;
      state.tokenExpiresAt = null;
      state.status = LOGGED_OUT;
      break;
    case actionTypes.LOGIN_ERROR:
      state.status = LOGIN_ERROR;
      break;
    case actionTypes.LOGIN_CANCELLED:
      state.status = LOGIN_CANCELLED;
      break;
  }
}, INITIAL_STATE);

export default loginReducer;
