import { fetcher } from "./fetcher";
import useSWR from "swr";
import { format } from "date-fns";
import { arrayToParamString } from "./utilities";

export type BBox = [number, number, number, number];

function useGridObjects(types: string[], bbox: BBox) {
  const typeParams = arrayToParamString("type", types);
  const { data, error, mutate } = useSWR(
    `/api/grid/objects?${typeParams}&bbox=${bbox[1]},${bbox[0]},${bbox[3]},${bbox[2]}`,
    fetcher
  );

  return {
    data,
    loading: !error && !data,
    error,
    mutate,
  };
}

function useFeatures(type: string, bbox: BBox, max?: number) {
  const { data, error, mutate } = useSWR(
    `/api/grid/features?type=${type}&bbox=${bbox[1]},${bbox[0]},${bbox[3]},${
      bbox[2]
    }${max ? `&max=${max}` : ""}`,
    fetcher
  );

  return {
    data,
    loading: !error && !data,
    error,
    mutate,
  };
}

function usePerformance(target_ids: string[], bbox: number[]) {
  const targetParams = arrayToParamString("target_id", target_ids);
  const { data, error, mutate } = useSWR(
    `/api/grid/performance?${targetParams}&bbox=${bbox[1]},${bbox[0]},${bbox[3]},${bbox[2]}`,
    fetcher
  );

  return {
    data,
    loading: !error && !data,
    error,
    mutate,
  };
}
function useChartData(
  checkedDependencies: { [objectId: number]: number[] },
  date_range: [Date | null, Date | null]
) {
  // Extract all target_ids from the checkedDependencies object
  const allTargetIds = Array.from(
    new Set(Object.values(checkedDependencies).flat())
  );

  const targetParams = arrayToParamString("target_id", allTargetIds);

  // Format the dates for use in the API endpoint.
  const startDate = date_range[0] ? format(date_range[0], "yyyy-MM-dd") : null;
  const endDate = date_range[1] ? format(date_range[1], "yyyy-MM-dd") : null;

  // Conditional SWR fetching: If there are no target_ids or missing dates, don't fetch.
  const shouldFetch = allTargetIds.length > 0 && startDate && endDate;
  const url = shouldFetch
    ? `/api/forecasts/latest?${targetParams}&start=${startDate}&end=${endDate}`
    : null;

  const { data, error, mutate } = useSWR(url, fetcher);

  return {
    data,
    loading: !error && !data,
    error,
    mutate,
  };
}

function useTargets() {
  const { data, error, mutate } = useSWR(`/api/models`, fetcher);

  return {
    data,
    loading: !error && !data,
    error,
    mutate,
  };
}

export {
  useGridObjects,
  useFeatures,
  useTargets,
  usePerformance,
  useChartData,
};
