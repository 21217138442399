import { parseISO, formatDistanceToNow, format } from "date-fns";

type isoformattedDate = string;
type ValidDate = Date | isoformattedDate;

function formatDate(date: ValidDate): string {
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return format(date, "yyyy-MM-dd");
}

function formatDateTime(date: ValidDate): string {
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return format(date, "yyyy-MM-dd HH:mm");
}

function formatTime(date: ValidDate): string {
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return format(date, "HH:mm");
}

function humanize(date: ValidDate): string {
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return formatDistanceToNow(date, { addSuffix: true });
}

export { parseISO, formatDate, formatDateTime, formatTime, humanize };
