/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import { call, cancelled, fork, put, select, take } from "redux-saga/effects";
import * as Sentry from "@sentry/react";

export const tokenExpiry = (state) => state.login?.tokenExpiresAt;
export const accessToken = (state) => state.login?.token;
export const currentLoginStatus = (state) => state.login?.status;

interface LoginResponse {
  access_token: string;
  email: string;
}

export const delay = (ms: number): Promise<unknown> =>
  new Promise((res) => setTimeout(res, ms));

export function postLogin(username: string, password: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const rawAxios = axios.create();
    rawAxios
      .post<LoginResponse>(
        "/api/login",
        { username, password },
        {
          transformRequest: [
            (data, headers) => {
              delete headers.common["Authorization"];
              return JSON.stringify(data);
            },
          ],
        }
      )
      .then((response) => {
        if (response.status === 401) {
          reject("Unauthorized");
        }
        Sentry.setUser({ email: response.data["email"] });
        resolve(response.data["access_token"]);
      })
      .catch(() => {
        reject("Server Error");
      });
  });
}

export function* authorize(username: string, password: string) {
  try {
    yield put({ type: "LOGIN_ATTEMPT" });
    const token: string = yield call(postLogin, username, password);
    yield put({ type: "SAVE_AUTH_TOKEN", token });
    yield put({ type: "LOGIN_SUCCESS" });
  } catch (error) {
    yield put({ type: "LOGIN_ERROR", error });
  } finally {
    if (yield cancelled()) {
      yield put({ type: "LOGIN_CANCELLED" });
    }
  }
}

export function* logoutFlow() {
  while (true) {
    yield take(["LOGOUT"]);
    yield call(postLogout);
    yield put({ type: "DELETE_AUTH_TOKEN" });
    yield put({ type: "LOGGED_OUT" });
  }
}

export function* loginFlow() {
  while (true) {
    const tokenExists = yield select(accessToken);
    const loginStatus = yield select(currentLoginStatus);

    if (!tokenExists && loginStatus != "Login error") {
      yield put({ type: "AUTO_LOGIN" });
      yield call(refresh);
    }

    const loginAction = yield take(["LOGIN_REQUEST"]);

    const { username: username, password: password } = loginAction;
    yield fork(authorize, username, password);

    // Take LOGGED_OUT to loop back to take(['LOGIN_REQUEST']) when the user
    // logs out
    const action = yield take(["LOGGED_OUT", "LOGIN_SUCCESS", "LOGIN_ERROR"]);
    if (action.type == "LOGIN_ERROR") {
      // we should inform the user
    }
  }
}

export function postLogout(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/logout")
      .then(() => {
        Sentry.setUser(null);
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postRefreshToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .post<LoginResponse>("/api/refresh", null, {
        transformRequest: [
          (data, headers) => {
            delete headers.common["Authorization"];
            return data;
          },
        ],
      })
      .then((response) => {
        Sentry.setUser({ email: response.data["email"] });
        resolve(response.data["access_token"]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function* refresh() {
  try {
    yield put({ type: "REFRESH_AUTH_TOKEN" });
    const token = yield call(postRefreshToken);
    yield put({ type: "SAVE_AUTH_TOKEN", token });
    yield put({ type: "REFRESH_SUCCESS" });
    yield put({ type: "LOGIN_SUCCESS" });
  } catch (error) {
    yield put({ type: "REFRESH_ERROR", error });
  } finally {
    if (yield cancelled()) {
      yield put({ type: "REFRESH_CANCELLED" });
    }
  }
}

export function* refreshFlow() {
  while (true) {
    const expiresAt = yield select(tokenExpiry);

    if (expiresAt) {
      const minutesLeft = (expiresAt - Date.now()) / 1000 / 60;
      if (minutesLeft <= 5) {
        yield fork(refresh);
        const action = yield take([
          "REFRESH_SUCCESS",
          "REFRESH_ERROR",
          "REFRESH_CANCELLED",
        ]);
        if (action.type === "REFRESH_ERROR") {
          // We should inform the user, and take them to the login page
        }
      }
    }

    yield call(delay, 30_000);
  }
}

export function* logActions() {
  while (true) {
    const action = yield take("*");
    console.log(action.type);
  }
}
