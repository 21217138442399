import axios from "axios";

export const fetcher = (url: string) =>
  axios
    .get(url, {
      validateStatus: function (status) {
        return status >= 200 && status < 300;
      },
    })
    .then((res) => res.data);
