import { ApiToken, ApiTokens } from "@/types";
import axios from "axios";
import React, { useEffect } from "react";
import {
  Block,
  Box,
  Button,
  Card,
  Columns,
  Table,
  Tag,
} from "react-bulma-components";
import { CreateApiTokenModal, ChangePassword } from "./components";
import { formatDate } from "@/utilities/datetime";
import { useMe } from "@/api/auth";

function UserProfile(): React.ReactElement {
  const { data: user } = useMe();

  const [apiTokens, setApiTokens] = React.useState<ApiTokens>([]);
  const [showCreateApiTokenModal, setShowCreateApiTokenModal] =
    React.useState(false);

  const [refreshApiTokens, setRefreshApiTokens] = React.useState(false);

  useEffect(() => {
    async function fetchApiTokens() {
      const response = await axios.get("/api/api_token");
      setApiTokens(response.data?.tokens ?? []);
    }
    fetchApiTokens();
  }, [refreshApiTokens]);

  const onNewTokenClose = () => {
    setShowCreateApiTokenModal(false);
    // TODO: message
    setRefreshApiTokens(!refreshApiTokens);
  };

  const onRevoked = async (token: ApiToken) => {
    await axios.delete(`/api/api_token/${token.jti}`);
    // TODO: message
    setRefreshApiTokens(!refreshApiTokens);
  };

  return (
    <>
      <Box alignContent="center">
        <Columns>
          <Columns.Column size={3}></Columns.Column>
          <Columns.Column size={6}>
            <Block>
              <Card>
                <Card.Header>
                  <Card.Header.Title textSize={3}>
                    User Profile
                  </Card.Header.Title>
                </Card.Header>
                <Card.Content>
                  <Block flexDirection="row">
                    <Block mr={3} renderAs="span">
                      Email
                    </Block>
                    <Tag color="info">{user?.email}</Tag>
                  </Block>
                  <Block flexDirection="row">
                    <Block mr={3} renderAs="span">
                      Group
                    </Block>
                    <Tag color="info">{user?.group_name}</Tag>
                  </Block>
                </Card.Content>
              </Card>
            </Block>
            <ChangePassword />
            <Block>
              <Card>
                <Card.Header>
                  <Card.Header.Title>API Tokens</Card.Header.Title>
                  <Card.Content>
                    <Button
                      color="success"
                      outlined
                      onClick={() => setShowCreateApiTokenModal(true)}
                    >
                      New Token
                    </Button>
                    <CreateApiTokenModal
                      show={showCreateApiTokenModal}
                      onClose={() => onNewTokenClose()}
                    />
                  </Card.Content>
                </Card.Header>
                <Card.Content>
                  <Table size="fullwidth">
                    <thead>
                      <th>Description</th>
                      <th>Created on</th>
                      <th>Expires on</th>
                      <th>&nbsp;</th>
                    </thead>
                    <tbody>
                      {apiTokens.map((token) => (
                        <tr key={token.jti}>
                          <td>{token.description}</td>
                          <td>{formatDate(token.created_at)}</td>
                          <td>
                            {token.expires_at !== null &&
                              formatDate(token.expires_at)}
                          </td>
                          <td>
                            <Button
                              color="danger"
                              onClick={() => onRevoked(token)}
                              outlined
                            >
                              Revoke
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Content>
              </Card>
            </Block>
          </Columns.Column>
        </Columns>
      </Box>
    </>
  );
}

export default UserProfile;
