export function arrayToParamString(
  paramName: string,
  values: (string | number)[]
): string {
  const encodedParams = values.map(
    (value) => `${paramName}=${encodeURIComponent(value)}`
  );
  return encodedParams.join("&");
}

type Value = string | number;

export function objectToParamString(
  obj: Record<string, Value | Value[]>
): string {
  const params: string[] = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value === null || value === undefined) {
        continue;
      }

      if (Array.isArray(value)) {
        const paramString = arrayToParamString(key, value);
        params.push(paramString);
      } else {
        const encodedValue = encodeURIComponent(value);
        const param = `${key}=${encodedValue}`;
        params.push(param);
      }
    }
  }

  return `?${params.join("&")}`;
}
