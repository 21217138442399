import { useState, useMemo } from "react";
import { Box, ActionIcon, Text, Switch, Menu } from "@mantine/core";
import axios from "axios";

import { GridForecasts, TargetFeature, Market } from "@/types";

import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from "mantine-react-table";

import { IconDownload, IconFilterFilled } from "@tabler/icons-react";

interface DataTableProps {
  gridForecastData: GridForecasts[];
  selectedTargetDeps: TargetFeature[];
  selectedMarket: Market | null | undefined;
  clickedScenarioIds: number[];
  setLoadingForecasts: (loading: boolean) => void;
  loadingForecasts: boolean;
}

function DataTableModal({
  gridForecastData,
  selectedTargetDeps,
  selectedMarket,
  loadingForecasts,
  setLoadingForecasts,
  clickedScenarioIds,
}: DataTableProps): React.ReactElement {
  //Forecast Filters
  const [offset, setOffset] = useState("None");
  const [stitched, setStitched] = useState(true);

  // Download logic

  const handleDownloadForecast = async () => {
    const feature_ids = selectedTargetDeps.map((dep) => dep.feature_id);

    setLoadingForecasts(true);
    const params = { stitched: "true", timezone: selectedMarket?.timezone };

    if (clickedScenarioIds.length > 0) {
      params["scenarios"] = clickedScenarioIds.join(",");
    }
    if (feature_ids.length > 0) {
      params["features"] = feature_ids.join(",");
    }

    if (offset != "None") {
      params["day_offset"] = offset;
    }

    const response = await axios.get(`/api/dashboard/forecasts/download`, {
      params: params,
    });

    if (response.status === 200) {
      setLoadingForecasts(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "forecast.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  // Function to flatten the GridForecasts data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const flattenGridForecasts = (gridForecasts: GridForecasts[]): any[] => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const flattenedData: any[] = [];

    gridForecasts.forEach((forecast) => {
      forecast.targets.forEach((target) => {
        target.vintages.forEach((vintage) => {
          vintage.batches.forEach((batch) => {
            batch.forecasts.forEach((forecastData) => {
              flattenedData.push({
                scheduled_at: vintage.scheduled_at,
                batch_id: batch.batch_id,
                object_name: forecast.object_name,
                series_name: target.series_name,
                timestamp: forecastData.timestamp,
                p01: forecastData.p01,
                p05: forecastData.p05,
                p10: forecastData.p10,
                p20: forecastData.p20,
                p30: forecastData.p30,
                p40: forecastData.p40,
                p50: forecastData.p50,
                p60: forecastData.p60,
                p70: forecastData.p70,
                p80: forecastData.p80,
                p90: forecastData.p90,
                p95: forecastData.p95,
                p99: forecastData.p99,
                value: forecastData.value,
                actual: forecastData.actual,
                error: forecastData.error,
              });
            });
          });
        });
      });
    });

    return flattenedData;
  };

  // Assuming gridForecastData is your original nested data
  const flattenedData = flattenGridForecasts(gridForecastData);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "object_name",
        header: "Object Name",
        filterVariant: "multi-select",
      },
      {
        accessorKey: "series_name",
        header: "Series Name",
        filterVariant: "multi-select",
      },
      {
        accessorKey: "scheduled_at",
        header: "Scheduled At",
        filterVariant: "date-range",
      },
      {
        accessorKey: "timestamp",
        header: "Timestamp",
        filterVariant: "date-range",
      },
      {
        accessorKey: "p05",
        header: "P05",
        filterVariant: "range",
      },
      {
        accessorKey: "p50",
        header: "P50",
        filterVariant: "range",
      },
      {
        accessorKey: "p95",
        header: "P95",
        filterVariant: "range",
      },
      {
        accessorKey: "batch_id",
        header: "Batch ID",
      },
      {
        accessorKey: "p01",
        header: "P01",
        filterVariant: "range",
      },
      {
        accessorKey: "p10",
        header: "P10",
        filterVariant: "range",
      },
      {
        accessorKey: "p20",
        header: "P20",
        filterVariant: "range",
      },
      {
        accessorKey: "p30",
        header: "P30",
        filterVariant: "range",
      },
      {
        accessorKey: "p40",
        header: "P40",
        filterVariant: "range",
      },
      {
        accessorKey: "p60",
        header: "P60",
        filterVariant: "range",
      },
      {
        accessorKey: "p70",
        header: "P70",
        filterVariant: "range",
      },
      {
        accessorKey: "p80",
        header: "P80",
        filterVariant: "range",
      },
      {
        accessorKey: "p90",
        header: "P90",
        filterVariant: "range",
      },
      {
        accessorKey: "p99",
        header: "P99",
        filterVariant: "range",
      },
      {
        accessorKey: "value",
        header: "Value",
        filterVariant: "range",
      },
      {
        accessorKey: "actual",
        header: "Actual",
        filterVariant: "range",
      },
      {
        accessorKey: "error",
        header: "Error",
        filterVariant: "range",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: flattenedData,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        console.log("Table row clicked", row.id);
      },
      // Additional styles or props
    }),
    state: { isLoading: loadingForecasts },
    enableFilterMatchHighlighting: false,
    enableFacetedValues: true,
    enableFilters: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: true,
    renderTopToolbarCustomActions: () => (
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          padding: "4px",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Menu withinPortal>
          <Menu.Target>
            <ActionIcon
              className="has-tooltip-bottom"
              data-tooltip="Filter Download"
              size="xl"
              style={{
                borderRadius: "8px",
                margin: "10px",
                backgroundColor: "#f0f0f0", // Same background as Menu.Dropdown
                padding: "4px",
              }}
            >
              <IconFilterFilled />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown
            style={{
              backgroundColor: "#f0f0f0", // Match background color
              borderRadius: "6px", // Match border radius
              padding: "4px", // Ensure consistent padding
            }}
          >
            <Menu.Label>Offset Operating Day</Menu.Label>
            {["None", "1", "2", "3"].map((item) => (
              <Menu.Item
                onClick={() => setOffset(item)}
                color={offset === item ? "blue" : undefined} // Highlight selected item
                key={item}
              >
                {item}
              </Menu.Item>
            ))}
            <Menu.Divider />
            <Menu.Label>Additional Filters</Menu.Label>
            <Menu.Item>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Text>Stitched</Text>
                <Switch
                  checked={stitched}
                  onChange={(event) => setStitched(event.currentTarget.checked)}
                  size="md"
                />
              </div>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <ActionIcon
          size="xl"
          style={{
            borderRadius: "6px",
            margin: "10px",
            backgroundColor: "#f0f0f0", // Match background color with Menu
            padding: "4px",
          }}
          className="has-tooltip-bottom"
          onClick={() => {
            handleDownloadForecast();
          }}
        >
          <IconDownload />
        </ActionIcon>
      </Box>
    ),
    mantinePaginationProps: {
      rowsPerPageOptions: ["50", "100", "150"],
    },
    initialState: {
      pagination: { pageSize: 50, pageIndex: 0 },
      density: "xs",
      showColumnFilters: true,
      columnVisibility: {
        object_name: true,
        series_name: true,
        scheduled_at: true,
        timestamp: true,
        p05: true,
        p50: true,
        p95: true,
        batch_id: false,
        p01: false,
        p10: false,
        p20: false,
        p30: false,
        p40: false,
        p60: false,
        p70: false,
        p80: false,
        p90: false,
        p99: false,
        value: false,
        actual: false,
        error: false,
      },
    },
    mantineTableContainerProps: { sx: { height: "620px" } },
  });

  return (
    <>
      <Box>
        {" "}
        <MantineReactTable table={table} />{" "}
      </Box>
    </>
  );
}

export default DataTableModal;
