import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Box,
  Button,
  Block,
  Container,
  Form,
  Hero,
  Icon,
  Columns,
  Message,
} from "react-bulma-components";

import Copyright from "../../components/Copyright";
import { connect } from "react-redux";
import { LOGIN_REQUEST } from "../../auth/actions";
import * as authStatus from "../../auth/states";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "@/store";
import { useLocalStorage } from "usehooks-ts";
import { useAccessToken } from "@/hooks";

interface LoginProps {
  status: authStatus.LoginState;
  dispatch: AppDispatch;
}

const Login = ({ status, dispatch }: LoginProps): React.ReactElement => {
  const navigate = useNavigate();
  const token = useAccessToken();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [returnTo, setReturnTo] = useLocalStorage("returnTo", "/app/dashboard");

  useEffect(() => {
    function postLoginRedirect(returnTo) {
      navigate(returnTo);
      setReturnTo("/app/dashboard");
    }

    if (!token) {
      return;
    }

    if (status === authStatus.LOGGED_IN) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios.defaults.validateStatus = function (status) {
        return status >= 200 && status < 500;
      };
      const postLoginRedirectTo =
        returnTo != "/login" ? returnTo : "/app/features";
      setTimeout(() => postLoginRedirect(postLoginRedirectTo), 50);
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }, [status, returnTo, setReturnTo, navigate, token]);

  const loginHandler = (e) => {
    e.preventDefault();
    dispatch({ type: LOGIN_REQUEST, username: username, password: password });
  };

  return (
    <Hero color="dark" size="fullheight">
      <Hero.Body>
        <Container>
          <Columns centered>
            <Columns.Column
              desktop={{ size: 4 }}
              tablet={{ size: 5 }}
              widescreen={{ size: 3 }}
            >
              {(status === authStatus.AUTO_LOGIN_ATTEMPT ||
                status === authStatus.LOGGED_IN) && (
                <Box>
                  <Columns centered>
                    <Columns.Column>
                      <Block>Login verification in progress</Block>
                      <Block>
                        <Button loading />
                      </Block>
                    </Columns.Column>
                  </Columns>
                </Box>
              )}
              {status !== authStatus.AUTO_LOGIN_ATTEMPT &&
                status !== authStatus.LOGGED_IN && (
                  <Box>
                    <form noValidate>
                      <Form.Field>
                        <Form.Label>Email</Form.Label>
                        <Form.Control>
                          <Form.Input
                            required
                            id="email"
                            name="email"
                            autoComplete="email"
                            onChange={(e) =>
                              setUsername(e.currentTarget?.value)
                            }
                          />
                          <Icon align="left">
                            <i className="fa fa-envelope"></i>
                          </Icon>
                        </Form.Control>
                      </Form.Field>

                      <Form.Field>
                        <Form.Label>Password</Form.Label>
                        <Form.Control>
                          <Form.Input
                            required
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(e) =>
                              setPassword(e.currentTarget?.value)
                            }
                          />
                          <Icon align="left">
                            <i className="fa fa-lock"></i>
                          </Icon>
                        </Form.Control>
                      </Form.Field>
                      {status === authStatus.LOGIN_ERROR && (
                        <Message color="danger">
                          <Message.Header>Login failed</Message.Header>
                          <Message.Body>
                            Verify your account details and try again.
                          </Message.Body>
                        </Message>
                      )}
                      <Form.Field>
                        <Form.Control>
                          <Button color="primary" onClick={loginHandler}>
                            Login
                          </Button>
                        </Form.Control>
                      </Form.Field>
                    </form>
                  </Box>
                )}
              <Box mt={5}>
                <Copyright />
              </Box>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state?.login?.status,
  };
};

export default connect(mapStateToProps)(Login);
