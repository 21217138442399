import { fetcher } from "./fetcher";
import useSWR from "swr";

export function useMe() {
  const { data, error, mutate } = useSWR("/api/me", fetcher);

  const loading = !data && !error;

  return {
    loading,
    data,
    error,
    mutate,
  };
}
