import { useCallback, useState, useEffect } from "react";
import useDebounce from "@/hooks/useDebounce";
import qs from "qs";
import { Scenario, TargetFeature, ModelRanking } from "@/types";
import moment from "moment-timezone";
import { useClickOutside } from "@mantine/hooks";
import axios from "axios";
import { addDays, subDays, format as formatDate, addHours } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { DatePicker } from "@mantine/dates";
import { useMe } from "@/api/auth";
import GridMap from "./GridMap/GridMap";

import DataTableModal from "./Table/DataTableModal";

import { GridForecasts, Market } from "@/types";

import {
  Button,
  Card,
  Text,
  Grid,
  Paper,
  Menu,
  LoadingOverlay,
  Title,
  Group,
  Box,
  ActionIcon,
  Collapse,
  Col,
  Popover,
  Container,
  Loader,
  Select,
  Tabs,
  Badge,
  TextInput,
  Table,
  Tooltip as MantineTooltip,
  MultiSelect,
} from "@mantine/core";

import {
  IconCalendar,
  IconAdjustmentsHorizontal,
  IconClock,
  IconMathFunction,
  IconMap,
  IconChartBar,
  IconRefresh,
  IconTable,
  IconDeviceFloppy,
} from "@tabler/icons-react";

import {
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

interface CustomLegendProps {
  visibility: Record<string, boolean>;
  toggleVisibility: (seriesName: string) => void;
  colors: Record<string, string>;
}

interface CustomTooltipProps {
  active?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any[];
  label?: string;
  timezone: string;
  seriesColors: seriesColorsProps;
}

interface seriesColorsProps {
  [key: string]: string;
}

type ChartDataItem = {
  timestamp: number;
  [key: string]: number | string | Date | null;
};

const seriesColors = {
  DALMP: "#4F97A3", // A soothing blue shade
  RTLMP: "#E67300", // A vibrant red shade
  ASM_DA_ECRS: "#6CAE75", // A fresh green
  ASM_DA_RRS: "#D64550", // A bright orange
  "DAM SP_CLR_PRC": "#D64550", // A bright orange
  ASM_DA_REGDOWN: "#8150AC", // A gentle purple
  "DAM RD_CLR_PRC": "#8150AC", // A gentle purple
  ASM_DA_REGUP: "#F284B6", // A soft pink
  "DAM RU_CLR_PRC": "#F284B6", // A soft pink
  ASM_DA_NONSPIN: "#E4CC37", // A sunny yellow
  RTLOAD_MINUS_RENEWABLES: "#4F97A3", // A soothing blue shade
};

export function Dashboard(): React.ReactElement {
  const { data: user } = useMe();

  const [uniqueMarkets, setUniqueMarkets] = useState<Market[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<Market | null>();

  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const [loadingScenarios, setLoadingScenarios] = useState<boolean>(false);
  const [clickedScenarioIds, setClickedScenarioIds] = useState<number[]>([]);

  //models
  const [models, setModels] = useState<ModelRanking[]>([]);
  const [selectedModelIds, setSelectedModelIds] = useState<{
    [key: number]: number | null;
  }>({}); // Store selected model_id per target_id

  const [chartData, setChartData] = useState<GridForecasts[]>([]);
  const [loadingForecasts, setLoadingForecasts] = useState<boolean>(false);
  const [hours, setHours] = useState<Array<string>>(["7", "8", "9"]);

  const [defaultDashboard, setDefaultDashboard] = useState<TargetFeature[]>([]);

  const [hoveredCard, setHoveredCard] = useState<number | null>(null);

  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [showModels, setShowModels] = useState<boolean>(false);

  const [refreshActive, setRefreshActive] = useState<boolean>(true);

  // Position the results container below the search bar
  const resultsContainerStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: 2, // Ensure it's above other content
    width: "100%", // Match the width to the TextInput or adjust as needed
    top: "100%", // Directly below the TextInput
    left: 0,
    backgroundColor: "#fff", // Solid color for readability
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Soft shadow for depth
    border: "1px solid #a0a0a0", // Subtle border
    borderRadius: "4px", // Match your design system's border radius
    overflowY: "auto",
    maxHeight: "240px",
  };

  //Tabs

  const [activeTab, setActiveTab] = useState<string | null>("charts");

  // Search

  /* Search */
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const loadTargetDeps = async () => {
    if (debouncedSearchTerm === "") {
      return;
    }
    setSearchResults([]);
    const response = await axios.get(`/api/target/features`, {
      params: {
        search: searchTerm,
        iso: selectedMarket && selectedMarket?.iso,
      },
    });
    if (response.status !== 200) {
      return;
    } else {
      setSearchResults(response.data);
    }
  };

  useEffect(() => {
    loadTargetDeps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  /* Target Cards */
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [showClickedCards, setShowClickedCards] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Array<TargetFeature>>([]);
  const [selectedTargetDeps, setSelectedTargetDeps] = useState<
    Array<TargetFeature>
  >([]);

  const [nonISOTargetDeps, setNonISOTargetDeps] = useState<TargetFeature[]>([]);

  /* Target Cards */
  /* ---------------------- */

  const handleCardClick = (targetDep) => {
    if (
      selectedTargetDeps.some(
        (t) => t.target_feature_id === targetDep.target_feature_id
      )
    ) {
      // If target is already in selectedTargets, remove it
      setSelectedTargetDeps((prev) =>
        prev.filter((t) => t.target_feature_id !== targetDep.target_feature_id)
      );
    } else {
      // If target is not in selectedTargets, add it
      setSelectedTargetDeps((prev) => [...prev, targetDep]);
    }
  };

  const renderCard = (targetDep: TargetFeature) => {
    const isSelected = selectedTargetDeps.some(
      (t) => t.target_feature_id === targetDep.target_feature_id
    );

    return (
      <Card
        shadow="lg"
        radius="lg"
        withBorder
        style={{
          margin: "5px",
          padding: "10px",
          backgroundColor: isSelected
            ? "rgba(173, 216, 230, 0.85)"
            : "rgba(255, 255, 255, 0.85)",
          border: "2px solid rgba(255, 255, 255, 0.7)", // Semi-opaque white border
          maxHeight: "60px",
          flexDirection: "column",
          justifyContent: "center",
        }}
        onClick={() => handleCardClick(targetDep)}
      >
        <Group
          style={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text fw={600} style={{ fontSize: "1rem" }}>
            <span
              style={{
                fontWeight: 400,
                fontSize: "0.9rem",
                marginLeft: "5px",
              }}
            >
              {targetDep.object_name}
            </span>
          </Text>
          <Group>
            <Badge
              variant="light"
              style={{
                fontSize: "0.8rem",
                padding: "5px 10px",
                backgroundColor:
                  seriesColors[targetDep.series_name || "Ancillary"], // Use seriesColors for dynamic color, default to Ancillary
                color: targetDep.series_name ? "#fff" : "#000",
              }} // Adjust badge size and colors dynamically
            >
              {targetDep.n_steps}
              {targetDep.freq} {targetDep.series_name}
            </Badge>
          </Group>
        </Group>
      </Card>
    );
  };

  const handleSaveDashboard = async () => {
    if (selectedTargetDeps.length === 0 && nonISOTargetDeps.length === 0) {
      return;
    }

    // Combine the selected targetDeps (matching ISO) with non-matching targetDeps
    const combinedTargetDeps = [...selectedTargetDeps, ...nonISOTargetDeps];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const response = await axios.post(`/api/dashboard`, {
      name: "Default", // change this later with input text

      target_feature_ids: combinedTargetDeps.map(
        (item) => item.target_feature_id
      ),
    });
  };

  const clickOutside = useClickOutside(() => {
    setShowSearchResults(false);
    setShowClickedCards(false);
  });

  const renderCards = (targetDeps: TargetFeature[]) => {
    if (targetDeps.length === 0) {
      return;
    } else {
      return (
        <Group style={{ border: "1px solid #d0d0d0" }}>
          <div ref={clickOutside} style={resultsContainerStyle}>
            {targetDeps.map((target, index) => (
              <div key={index}>{renderCard(target)}</div>
            ))}
          </div>
        </Group>
      );
    }
  };

  // Date Range Filter
  const [showDateRange, setShowDateRange] = useState(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    subDays(new Date(), 5),
    addDays(new Date(), 2),
  ]);

  const loadDashboards = async () => {
    const response = await axios.get(`/api/dashboards`);
    if (response.status !== 200) {
      return;
    } else {
      if (response.data.length > 0) {
        setDefaultDashboard(response.data[0].target_features);
      }
    }
  };

  useEffect(() => {
    loadDashboards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultDashboard) {
      // Filter out the targetDeps that match the ISO
      const filteredTargetDeps = defaultDashboard.filter(
        (targetDep) => targetDep.iso === selectedMarket?.iso
      );
      // Store the targetDeps that don't match the ISO
      const nonISO = defaultDashboard.filter(
        (targetDep) => targetDep.iso !== selectedMarket?.iso
      );

      setSelectedTargetDeps(filteredTargetDeps);
      setNonISOTargetDeps(nonISO); // We should just handle this on the backend with a flag "override" or something
    }
  }, [defaultDashboard, selectedMarket]);

  useEffect(() => {
    const marketMap = new Map<string, string>();
    const getTargets = async () => {
      const response = await axios.get("/api/targets");

      // Iterate over targets to fill the marketMap
      response.data.forEach((target) => {
        if (target.iso && target.timezone) {
          // Ensure both properties exist
          marketMap.set(target.iso, target.timezone);
        }
      });

      // Convert the map to an array of Market objects
      const uniqueMarkets = Array.from(marketMap, ([iso, timezone]) => ({
        iso,
        timezone,
      }));

      setUniqueMarkets(uniqueMarkets);

      // Set the first market as the selected market if any exist
      if (uniqueMarkets.length > 0) {
        setSelectedMarket(uniqueMarkets[0]);
      } else {
        setSelectedMarket(null); // Reset to null if no markets are available
      }
    };
    getTargets();
  }, [user]); // empty dependency means only run once

  const handleClickTargetList = () => {
    setShowClickedCards((prev) => !prev);
  };

  const fetchScenarios = useCallback(
    async (targetDeps) => {
      setLoadingScenarios(true);
      if (!targetDeps || targetDeps.length === 0) {
        setLoadingScenarios(false);
        return;
      }
      // Extract unique target_ids
      const uniqueTargetIds = new Set(targetDeps.map((dep) => dep.target_id));

      const params = { limit: 100, target_id: Array.from(uniqueTargetIds) };

      if (dateRange[0] && dateRange[1]) {
        params["start"] = formatDate(dateRange[0], "yyyy-MM-dd");
        params["end"] = formatDate(dateRange[1], "yyyy-MM-dd");
      } else {
        return;
      }

      if (hours.length > 0) {
        params["hours"] = hours.join(",");
      }
      const response = await axios.get(`/api/scenarios`, {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });

      if (response.status == 200) {
        setScenarios(response.data);
        setClickedScenarioIds(response.data.map((scenario) => scenario.id));
      }
      setLoadingScenarios(false);
    },
    [dateRange, hours]
  );

  const fetchModels = useCallback(
    async (targetDeps) => {
      // Extract unique target_ids

      const filteredTargetDeps = targetDeps.filter(
        (dep) => dep.iso === selectedMarket?.iso
      );

      const uniqueTargetIds = new Set(
        filteredTargetDeps.map((dep) => dep.target_id)
      );

      if (uniqueTargetIds?.size === 0) {
        return;
      }

      const params = { limit: 100, target_id: Array.from(uniqueTargetIds) };

      const response = await axios.get(`/api/model/rankings`, {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });

      if (response.status == 200) {
        setModels(response.data);
      }
    },
    [selectedMarket]
  );

  const handleModelChange = (target_id: number, model_id: number | null) => {
    setSelectedModelIds((prev) => ({
      ...prev,
      [target_id]: model_id,
    }));
  };

  const fetchGridForecasts = useCallback(async () => {
    console.log("fetching forecasts");
    const feature_ids = selectedTargetDeps.map((dep) => dep.feature_id);

    if (clickedScenarioIds?.length === 0 || feature_ids?.length === 0) {
      return;
    }

    setLoadingForecasts(true);
    const params = { stitched: true, stages: "Production" };

    if (clickedScenarioIds.length > 0) {
      params["scenarios"] = clickedScenarioIds.join(",");
    }
    if (feature_ids.length > 0) {
      params["features"] = feature_ids.join(",");
    }

    const uniqueModelIds = [...new Set(Object.values(selectedModelIds))];
    if (uniqueModelIds.length > 0) {
      params["models"] = uniqueModelIds.join(",");
    }

    const response = await axios.get(`/api/dashboard/forecasts`, {
      params: params,
    });

    if (response.status === 200) {
      setChartData(response.data);
    }
    setLoadingForecasts(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedScenarioIds, selectedTargetDeps, selectedModelIds]);

  useEffect(() => {
    setRefreshActive(true);
  }, [selectedTargetDeps, clickedScenarioIds, selectedModelIds]);

  useEffect(() => {
    fetchScenarios(selectedTargetDeps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchScenarios, selectedTargetDeps, dateRange]);

  useEffect(() => {
    fetchModels(selectedTargetDeps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchModels, selectedTargetDeps]);

  const renderModelSelect = () => {
    const uniqueTargetModelsMap = new Map<
      number,
      { target_id: number; models: ModelRanking[]; targetDep: TargetFeature }
    >();

    selectedTargetDeps.forEach((targetDep) => {
      if (!uniqueTargetModelsMap.has(targetDep.target_id)) {
        uniqueTargetModelsMap.set(targetDep.target_id, {
          target_id: targetDep.target_id,
          models: models.filter(
            (model) =>
              model.target_id === targetDep.target_id && model.model_id !== null
          ), // Filter out models with null model_id
          targetDep, // Include the full targetDep object for rendering the Badge
        });
      }
    });

    const targetModels = Array.from(uniqueTargetModelsMap.values());

    return targetModels.map(({ target_id, models, targetDep }) => (
      <Grid
        gutter="xs"
        align="center"
        style={{ padding: "10px" }}
        key={target_id}
      >
        <Col span={6}>
          <Group style={{ height: "100%", width: "100%" }} align="center">
            <Badge
              variant="light"
              style={{
                fontSize: "0.8rem",
                padding: "5px 10px",
                backgroundColor:
                  seriesColors[targetDep.series_name || "Ancillary"], // Use seriesColors for dynamic color, default to Ancillary
                color: targetDep.series_name ? "#fff" : "#000",
              }} // Adjust badge size and colors dynamically
            >
              {targetDep.n_steps}
              {targetDep.freq} {targetDep.series_name}
            </Badge>
          </Group>
        </Col>
        <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Select
            placeholder="Pick model"
            value={String(selectedModelIds[target_id])}
            style={{ minWidth: "100%" }}
            data={models.map((model) => ({
              value: String(model.model_id),
              label: `Model ID ${String(model.model_id)} - ${moment(
                model.range_end
              ).format("MMM DD")}`,
            }))}
            onChange={(model_id) =>
              handleModelChange(target_id, Number(model_id))
            }
          />
        </Col>
      </Grid>
    ));
  };

  function renderRange(timestamp: string, formatter = "MMM. dd") {
    const dt = new Date(timestamp);
    if (!selectedMarket) {
      return;
    }
    const dtTz = utcToZonedTime(dt, selectedMarket && selectedMarket?.timezone);
    return format(dtTz, formatter);
  }

  async function downloadScenario(id: number) {
    if (user.admin) {
      const response = await axios.get(`/api/scenario/${id}/download`);
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    }
  }

  const onClickScenario = (scenarioId: number) => {
    setClickedScenarioIds((prevIds) => {
      const isIdPresent = prevIds.includes(scenarioId);
      if (isIdPresent) {
        return prevIds.filter((id) => id !== scenarioId);
      } else {
        return [...prevIds, scenarioId];
      }
    });
  };

  const onClickAllScenario = () => {
    if (clickedScenarioIds.length === scenarios.length) {
      setClickedScenarioIds([]);
    } else {
      setClickedScenarioIds(scenarios.map((scenario) => scenario.id));
    }
  };

  const cardStyle = (id: number): React.CSSProperties => ({
    cursor: "pointer",
    margin: "10px 0",
    padding: "15px",
    boxShadow:
      hoveredCard === id
        ? "0 4px 6px rgba(0,0,0,0.15)"
        : "0 2px 4px rgba(0,0,0,0.1)",
    transition: "box-shadow 0.1s ease, background-color 0.1s ease",
    backgroundColor: clickedScenarioIds.includes(id) ? "#e0e0e0" : "#f3f3f3",
    border: "1px solid #d0d0d0", // Subtle border color
    borderRadius: "8px", // Optional: Adds rounded corners for a smoother look
  });

  const formatDateRange = (range: [Date | null, Date | null]) => {
    if (range[0] && range[1]) {
      return `${format(range[0], "MMM dd, yyyy")} - ${format(
        range[1],
        "MMM dd, yyyy"
      )}`;
    } else if (range[0]) {
      return `From ${format(range[0], "MMM dd, yyyy")}`;
    } else if (range[1]) {
      return `Until ${format(range[1], "MMM dd, yyyy")}`;
    }
    return "Select Date Range";
  };

  const [visibility, setVisibility] = useState({});

  useEffect(() => {
    const allSeriesLines = new Set<string>(); // Explicitly type the Set as string
    chartData.forEach((gridForecast) => {
      gridForecast.targets.forEach((target) => {
        ["actual", "p50", "p05", "p95", "diff"].forEach((attr) => {
          const seriesLine = `${target.series_name} - ${attr}`;
          allSeriesLines.add(seriesLine);
        });
      });
    });

    // Explicitly define the type of initialVisibility
    const initialVisibility: Record<string, boolean> = {};
    allSeriesLines.forEach((seriesLine) => {
      initialVisibility[seriesLine] = true; // Initialize all series as visible
    });
    setVisibility(initialVisibility);
  }, [chartData]);

  const toggleVisibility = (seriesName) => {
    setVisibility((currentVisibility) => ({
      ...currentVisibility,
      [seriesName]: !currentVisibility[seriesName],
    }));
  };

  const CustomLegend = ({
    visibility,
    toggleVisibility,
    colors,
  }: CustomLegendProps) => {
    const seriesNames = Array.from(
      new Set(Object.keys(visibility).map((key) => key.split(" - ")[0]))
    );

    return (
      <>
        {" "}
        {seriesNames.map((seriesName) => (
          <Box key={seriesName} mb="md" p="xs" sx={{ width: "100%" }}>
            {/* Series Name Header */}
            <Text
              size="sm"
              sx={{
                backgroundColor: `${colors[seriesName]}20`,
                padding: "4px 8px",
                borderRadius: "4px",
                color: colors[seriesName],
                textAlign: "center",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              {seriesName}
            </Text>

            {/* Attributes Row */}
            <Group position="apart" style={{ width: "100%" }}>
              {["actual", "p50", "diff"].map((attribute) => {
                const key = `${seriesName} - ${attribute}`;
                const isActive = visibility[key];

                return (
                  <Box
                    key={key}
                    onClick={() => toggleVisibility(key)}
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      flex: 1,
                      ":hover": {
                        backgroundColor: isActive
                          ? `${colors[seriesName]}33`
                          : theme.colors.gray[2],
                      },
                    })}
                  >
                    {/* Visual indicator */}
                    <Box
                      sx={{
                        width: attribute === "diff" ? 14 : 24,
                        height: attribute === "diff" ? 14 : 3,
                        borderRadius: attribute === "diff" ? "50%" : "0%",
                        backgroundColor:
                          attribute === "diff"
                            ? isActive
                              ? colors[seriesName]
                              : "#AAA"
                            : "transparent",
                        borderStyle: attribute === "p50" ? "dashed" : "solid",
                        borderWidth: attribute !== "diff" ? "2px" : "0",
                        borderColor: isActive ? colors[seriesName] : "#AAA",
                      }}
                    />
                    <Text
                      size="xs"
                      style={{
                        color: isActive ? colors[seriesName] : "#AAA",
                        marginTop: 2,
                      }}
                    >
                      {attribute === "actual"
                        ? "Actual"
                        : attribute == "diff"
                        ? "CI 90%"
                        : "P50"}
                    </Text>
                  </Box>
                );
              })}
            </Group>
          </Box>
        ))}
      </>
    );
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
    timezone,
    seriesColors,
  }: CustomTooltipProps) => {
    const validAttributes = ["actual", "p05", "p50", "p95"]; // Defined order of attributes

    const order = { actual: 1, p05: 2, p50: 3, p95: 4 };

    if (!active || !payload || payload.length === 0) return null;

    const sortedPayload = payload
      .filter((p) => validAttributes.includes(p.name.split(" - ")[1]))
      .sort((a, b) => {
        const [seriesNameA, attrA] = a.name.split(" - ");
        const [seriesNameB, attrB] = b.name.split(" - ");
        if (seriesNameA === seriesNameB) {
          return (order[attrA] || 5) - (order[attrB] || 5);
        }
        return seriesNameA.localeCompare(seriesNameB);
      });

    if (sortedPayload === undefined || sortedPayload.length === 0) return null;

    if (label === undefined || label === null) return null;

    const dt = new Date(label);
    const dtTz = utcToZonedTime(dt, timezone);
    const he = utcToZonedTime(addHours(dt, 1), timezone);
    const dtFormatted = format(dtTz, "LLL. do 'T'HH:mm 'UTC'XXX", {
      timeZone: timezone,
    });
    const heFormatted = format(he, "HH", { timeZone: timezone });
    const timeTitle = `HE${
      heFormatted === "00" ? 24 : heFormatted
    } - ${dtFormatted}`;

    return (
      <div
        style={{
          margin: "10px",
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <div
          style={{ textAlign: "center", padding: "10px", fontWeight: "bold" }}
        >
          {timeTitle}
        </div>
        <Table striped highlightOnHover>
          <thead>
            <tr>
              <th>Series Name</th>
              <th>Attribute</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {sortedPayload.map((entry, index) => {
              const [seriesName, attribute] = entry.name.split(" - ");
              const displayAttribute =
                attribute === "actual" ? "Actual" : attribute.toUpperCase();
              const backgroundColor = seriesColors[seriesName]
                ? seriesColors[seriesName] + "33"
                : "#f3f3f3";

              return (
                <tr key={`item-${index}`} style={{ backgroundColor }}>
                  <td>{seriesName}</td>
                  <td
                    style={attribute === "actual" ? { fontWeight: "bold" } : {}}
                  >
                    {displayAttribute}
                  </td>
                  <td
                    style={attribute === "actual" ? { fontWeight: "bold" } : {}}
                  >
                    {entry.value}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  const objectChart = (gridForecast, index) => {
    let transformedData: ChartDataItem[] = [];
    gridForecast.targets.forEach((target) => {
      target.vintages.forEach((vintage) => {
        vintage.batches.forEach((batch) => {
          batch.forecasts.forEach((forecast) => {
            const forecastTimestamp = forecast.timestamp; // Use string timestamp directly

            let dataItem = transformedData.find(
              (item) => item.timestamp === forecastTimestamp
            );

            if (!dataItem) {
              // Initialize dataItem if not found
              dataItem = { timestamp: forecastTimestamp };
              transformedData.push(dataItem);
            }

            // Now that dataItem is guaranteed to be defined, use the non-null assertion operator
            // when accessing it for setting properties.
            ["actual", "p50", "p95", "p05"].forEach((attr) => {
              const seriesLineKey = `${target.series_name} - ${attr}`;
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              dataItem![seriesLineKey] = forecast[attr]; // Use ! to assert non-null

              if (attr === "p95" || attr === "p05") {
                const diffKey = `${target.series_name} - diff`;
                // Also assert dataItem is non-null here
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                if (!dataItem![diffKey] && forecast.p95 && forecast.p05) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  dataItem![diffKey] = forecast.p95 - forecast.p05;
                }
              }
            });
          });
        });
      });
    });

    // Sort chart data by timestamp
    transformedData = transformedData.sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );

    transformedData.forEach((item) => {
      item.timestamp = moment(item.timestamp).valueOf();
    });
    //Timezones

    // Determine the range of your data
    const startDate = moment
      .tz(
        transformedData[0]?.timestamp,
        selectedMarket && selectedMarket.timezone
          ? selectedMarket.timezone
          : "UTC"
      )
      .startOf("day");
    const endDate = moment
      .tz(
        transformedData[transformedData.length - 1]?.timestamp,
        selectedMarket && selectedMarket.timezone
          ? selectedMarket.timezone
          : "UTC"
      )
      .startOf("day");

    const ticks: number[] = [];

    for (
      let m = moment(startDate);
      m.diff(endDate, "days") <= 0;
      m.add(1, "days")
    ) {
      ticks.push(m.valueOf()); // .valueOf() gives you the timestamp in milliseconds
    }

    return (
      <div key={index} style={{ marginBottom: "20px" }}>
        <Container size="xl" style={{ marginBottom: "10px" }}>
          <Grid align="center">
            <Col span={4}>
              <Title order={4} style={{ margin: "10px" }}>
                {gridForecast.object_name}
                {gridForecast.power_plant && gridForecast.plant_capacity ? (
                  <>
                    {" - "}
                    {gridForecast.power_plant} ({gridForecast.plant_capacity}{" "}
                    MW)
                  </>
                ) : null}
              </Title>
            </Col>
            <Col
              span={8}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CustomLegend
                visibility={visibility}
                toggleVisibility={toggleVisibility}
                colors={seriesColors}
              />
            </Col>
          </Grid>
        </Container>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={transformedData}>
            <CartesianGrid
              stroke="#eee"
              strokeDasharray="5 5 5"
              strokeWidth={2}
            />
            <XAxis
              tick={{ fill: "gray", fontSize: 14 }}
              dataKey="timestamp"
              scale="time"
              type="number"
              domain={["dataMin", "dataMax"]}
              tickFormatter={(unixTime) =>
                moment(unixTime)
                  .tz(
                    selectedMarket && selectedMarket.timezone
                      ? selectedMarket.timezone
                      : "UTC"
                  )
                  .format("YYYY-MM-DD")
              }
              ticks={ticks}
            />
            <YAxis tick={{ fill: "gray", fontSize: 14 }} />
            <Tooltip
              content={
                <CustomTooltip
                  timezone={
                    selectedMarket && selectedMarket.timezone
                      ? selectedMarket.timezone
                      : "UTC"
                  }
                  seriesColors={seriesColors}
                />
              }
            />

            {Object.keys(visibility)
              //.filter((key) => visibility[key])
              .map((seriesLine) => {
                const [seriesName, attribute] = seriesLine.split(" - ");
                if (attribute === "p95" && visibility[`${seriesName} - diff`]) {
                  return (
                    <Area
                      key={seriesLine}
                      type="monotone"
                      stroke={seriesColors[seriesName]}
                      dataKey={seriesLine}
                      strokeWidth={0}
                      fill="transparent"
                      isAnimationActive={false}
                      dot={false}
                    />
                  );
                } else if (attribute === "actual" && visibility[seriesLine]) {
                  return (
                    <Area
                      key={seriesLine}
                      type="monotone"
                      dataKey={seriesLine}
                      stroke={seriesColors[seriesName]}
                      isAnimationActive={false}
                      fill="transparent"
                      strokeWidth={1}
                    />
                  );
                } else if (attribute === "p50" && visibility[seriesLine]) {
                  return (
                    <Area
                      key={seriesLine}
                      type="monotone"
                      dataKey={seriesLine}
                      stroke={seriesColors[seriesName]}
                      isAnimationActive={false}
                      fill="transparent"
                      strokeWidth={1}
                      strokeDasharray="5 5"
                    />
                  );
                } else if (
                  attribute === "p05" &&
                  visibility[`${seriesName} - diff`]
                ) {
                  return (
                    <Area
                      key={seriesLine}
                      type="monotone"
                      dataKey={seriesLine}
                      stackId={seriesName}
                      stroke={seriesColors[seriesName]}
                      strokeWidth={0}
                      fill="transparent" // This makes the area transparent
                      isAnimationActive={false}
                    />
                  );
                } else if (
                  attribute === "diff" &&
                  visibility[`${seriesName} - diff`]
                ) {
                  return (
                    <Area
                      key={seriesLine}
                      dataKey={seriesLine}
                      stackId={seriesName}
                      fill={seriesColors[seriesName]}
                      fillOpacity={0.2} // Adjust for desired visibility
                      isAnimationActive={false}
                      stroke={seriesColors[seriesName]}
                      strokeWidth={0}
                      legendType="none"
                      label="none"
                      tooltipType="none"
                    />
                  );
                }
              })}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <Grid>
      <Grid.Col
        span={3} // Keep this span to allocate 3 columns to the drawer
        style={{
          paddingLeft: "20px",
          backgroundColor: "#f9f9f9",
          position: "relative",
        }}
      >
        <Group
          position="apart"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            order={4}
            style={{
              marginLeft: "10px",
              marginTop: "20px",
              marginBottom: "20px",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Filters
          </Title>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <ActionIcon
              className={`has-tooltip-bottom ${refreshActive ? "pulsate" : ""}`}
              data-tooltip="Refresh Data"
              size="xl"
              onClick={() => {
                fetchGridForecasts();
                setRefreshActive(false); // Stop pulsating after clicking
                console.log("Clicked");
              }}
              style={{
                animation: refreshActive ? "pulsate 1.5s infinite" : "none",
                color: refreshActive ? "blue" : "inherit",
              }}
            >
              <IconRefresh />
            </ActionIcon>

            <ActionIcon
              className="has-tooltip-bottom"
              data-tooltip="Save Selection"
              size="xl"
              onClick={() => handleSaveDashboard()}
            >
              <IconDeviceFloppy />
            </ActionIcon>
            <ActionIcon
              variant="light"
              color={showFilters ? "blue" : "gray"}
              onClick={() => setShowFilters(!showFilters)}
              size="xl"
            >
              <IconAdjustmentsHorizontal />
            </ActionIcon>
          </div>
        </Group>
        <Collapse in={showFilters}>
          <Grid gutter="xs" align="center" style={{ padding: "10px" }}>
            <Col span={4}>
              <Text> Dates </Text>
            </Col>

            <Col
              span={8}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Popover
                opened={showDateRange}
                onChange={setShowDateRange}
                position="bottom"
                withArrow
              >
                <Popover.Target>
                  <Button
                    variant="light"
                    color="gray"
                    onClick={() => setShowDateRange((o) => !o)}
                  >
                    <IconCalendar style={{ marginRight: "5px" }} />

                    <Text>{formatDateRange(dateRange)}</Text>
                  </Button>
                </Popover.Target>
                <Popover.Dropdown>
                  <DatePicker
                    type="range"
                    value={dateRange}
                    onChange={(range) => {
                      setDateRange(range);
                    }}
                  />
                </Popover.Dropdown>
              </Popover>
            </Col>
            <Col span={4}>
              <Group style={{ height: "100%", width: "100%" }} align="center">
                <Text> Market </Text>
              </Group>
            </Col>

            <Col
              span={8}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Select
                placeholder="Pick value"
                value={selectedMarket?.iso}
                style={{ minWidth: "100%" }}
                data={uniqueMarkets.map((market) => ({
                  value: market.iso, // Use iso as the value for each option
                  label: market.iso, // Typically, you'd show the iso (or any other identifier) as the label
                }))}
                onChange={(iso) => {
                  const market = uniqueMarkets.find(
                    (market) => market.iso === iso
                  );
                  setSelectedMarket(market); // Set the found market object as the selectedMarket
                  setSelectedModelIds({}); // Reset selectedModelIds when changing markets
                }}
              />
            </Col>
          </Grid>
          <div style={{ position: "relative", width: "100%" }}>
            <Grid gutter="xs" align="center" style={{ padding: "10px" }}>
              <Col span={2}>
                <Text> Targets </Text>
              </Col>
              <Col span={2}>
                <MantineTooltip label="Defaults to the saved presets in the matching ISO.">
                  <ActionIcon
                    size="lg"
                    onClick={handleClickTargetList}
                    style={{
                      backgroundColor: selectedTargetDeps.length
                        ? "rgba(173, 216, 230, 0.85)"
                        : undefined,
                      border: "2px solid rgba(255, 255, 255, 0.7)",
                      borderRadius: "8px",
                    }}
                  >
                    <Text>{selectedTargetDeps.length}</Text>
                  </ActionIcon>
                </MantineTooltip>
              </Col>
              <Col span={8}>
                <TextInput
                  style={{ minWidth: "100%" }}
                  icon={<i className="fa fa-search" />}
                  placeholder="Search objects... "
                  onChange={(event) =>
                    handleSearchChange(event.currentTarget.value)
                  }
                  onClick={() => {
                    setSearchTerm("");
                    setSearchResults([]);
                    setShowSearchResults(true);
                    setShowClickedCards(false);
                  }}
                  value={searchTerm}
                  onKeyDown={(event) => {
                    if (event.key === "Escape") {
                      handleSearchChange(""); // reset the searchTerm to an empty string
                      setSearchResults([]);
                    }
                    if (event.key === "Enter") {
                      loadTargetDeps();
                    }
                  }}
                />
              </Col>

              {showClickedCards && selectedTargetDeps.length > 0
                ? renderCards(selectedTargetDeps)
                : showSearchResults && searchResults.length > 0
                ? renderCards(searchResults)
                : null}
            </Grid>
          </div>
          <Grid gutter="xs" align="center" style={{ padding: "10px" }}>
            <Col span={4}>
              <MantineTooltip
                label="Select hour(s) the forecasts were generated. Defaults to all hours."
                multiline={true}
                position="top"
                withArrow
              >
                <Text> Runtime</Text>
              </MantineTooltip>
            </Col>
            <Col
              span={8}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <MultiSelect
                clearButtonProps={{ "aria-label": "Clear selection" }}
                clearable
                placeholder="Select runtime hours..."
                style={{ minWidth: "100%" }}
                data={Array.from({ length: 24 }, (_, i) => ({
                  label: `HE${i + 1}`,
                  value: `${i + 1}`,
                }))}
                value={hours}
                onChange={setHours}
              />
            </Col>
          </Grid>
        </Collapse>
        <>
          <Group position="apart">
            <Title
              order={4}
              style={{
                marginLeft: "10px",
                marginBottom: "20px",
                marginTop: "20px",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Models
            </Title>
            <Group>
              <ActionIcon
                variant="light"
                color={showModels ? "blue" : "gray"}
                onClick={() => setShowModels(!showModels)}
                size="xl"
              >
                <IconMathFunction />
              </ActionIcon>
            </Group>
          </Group>
          <Collapse in={showModels}>{renderModelSelect()}</Collapse>
        </>

        <Group position="apart">
          <Title
            order={4}
            style={{
              marginLeft: "10px",
              marginBottom: "20px",
              marginTop: "20px",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Vintages
          </Title>
          <Group>
            <MantineTooltip
              label="Select or deselect all vintages"
              position="top"
              withArrow
            >
              <Button
                onClick={() => onClickAllScenario()}
                variant={"light"}
                color={clickedScenarioIds.length === 0 ? "gray" : "blue"}
              >
                {loadingScenarios ? (
                  <Loader
                    size="xs"
                    color="gray"
                    style={{ marginRight: "16px" }}
                  />
                ) : (
                  <Text>
                    {" "}
                    {clickedScenarioIds ? clickedScenarioIds.length : 0} /{" "}
                    {scenarios ? scenarios.length : 0}
                  </Text>
                )}
              </Button>
            </MantineTooltip>
          </Group>
        </Group>

        <div
          style={{
            overflowY: "scroll",
            maxHeight: showFilters ? "45vh" : "75vh",
            height: "80vh",
            borderTop: "2px solid #e0e0e0",
            borderBottom: "2px solid #e0e0e0",
          }}
        >
          {scenarios.map((scenario) => (
            <Card
              key={scenario.id}
              className="has-tooltip-bottom"
              data-tooltip={`Scenario ${scenario.id}:\n - From: ${scenario.range_start}\n - To: ${scenario.range_end}`}
              onMouseEnter={() => setHoveredCard(scenario.id)}
              onMouseLeave={() => setHoveredCard(null)}
              style={cardStyle(scenario.id)}
              onClick={() => onClickScenario(scenario.id)}
              radius="md"
            >
              <Group
                position="apart"
                style={{ width: "100%", alignItems: "center" }}
              >
                <Group>
                  <Text style={{ color: "black", fontSize: 15 }}>
                    {renderRange(scenario.range_start)} -{" "}
                    {renderRange(scenario.range_end, "dd")}
                  </Text>
                  <IconClock color="black" size={20} />
                  <Text style={{ color: "black", fontSize: 15 }}>
                    {scenario.scheduled_at_human}
                  </Text>
                  <Badge
                    variant="light"
                    style={{
                      fontSize: "0.8rem",
                      padding: "5px 10px",
                      backgroundColor:
                        seriesColors[scenario.series_name || "Ancillary"], // Use seriesColors for dynamic color, default to Ancillary
                      color: scenario.series_name ? "#fff" : "#000",
                    }}
                  >
                    {scenario.series_name || "Ancillary"}
                  </Badge>
                </Group>

                {(hoveredCard === scenario.id ||
                  clickedScenarioIds.includes(scenario.id)) && (
                  <Menu withinPortal position="bottom-end" shadow="md">
                    <Menu.Target>
                      <ActionIcon size="sm">
                        <i
                          className="fas fa-ellipsis-h"
                          style={{ fontSize: "1.25em" }}
                        ></i>
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {user && user.admin && (
                        <Menu.Item
                          icon={<i className="fas fa-download" />}
                          onClick={() => downloadScenario(scenario.id)}
                        >
                          Download
                        </Menu.Item>
                      )}
                      {/* Add more Menu.Item here as needed */}
                    </Menu.Dropdown>
                  </Menu>
                )}
              </Group>
            </Card>
          ))}
        </div>
      </Grid.Col>
      <Grid.Col
        span="auto"
        style={{
          minWidth: "300px", // Ensure main content doesn't get too small
          padding: "20px",
        }}
      >
        <Tabs
          value={activeTab}
          onTabChange={(value) => {
            if (value !== null) {
              setActiveTab(value);
            }
          }}
        >
          <Tabs.List
            sx={{
              display: "flex",
              "& .mantine-Tabs-tab": {
                padding: "0.75rem 2rem",
                height: "40px",
                width: "200px",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
                margin: "0 0.5rem",
                transition: "background-color 0.3s, transform 0.3s",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              },
              "& .mantine-Tabs-tab[data-active]": {
                backgroundColor: "rgba(0, 13, 255, 0.1)",
                color: "#000",
                fontWeight: "bold",
                transform: "scale(1.05)",
              },
            }}
          >
            {user && user.admin && (
              <>
                <Tabs.Tab value="grid">
                  <Group position="center" spacing="sm">
                    <IconMap size={24} />
                    <span>Grid</span>
                  </Group>
                </Tabs.Tab>
              </>
            )}
            <Tabs.Tab value="charts">
              <Group position="center" spacing="sm">
                <IconChartBar size={24} />
                <span>Charts</span>
              </Group>
            </Tabs.Tab>
            <Tabs.Tab value="table">
              <Group position="center" spacing="sm">
                <IconTable size={24} />
                <span>Table</span>
              </Group>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="grid">
            <GridMap />
          </Tabs.Panel>
          <Tabs.Panel value="charts">
            <Group
              position="apart"
              style={{
                marginTop: "10px",
                marginBottom: "20px",
                marginRight: "20px",
              }}
            ></Group>
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <LoadingOverlay visible={loadingForecasts} />
              <Paper
                p="md"
                shadow="xs"
                style={{
                  minHeight: "80vh",
                  overflowY: "scroll",
                  maxHeight: "80vh",
                }}
              >
                {chartData.map((gridForecast, index) => (
                  <div key={index}>{objectChart(gridForecast, index)}</div>
                ))}
              </Paper>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="table">
            <DataTableModal
              gridForecastData={chartData}
              selectedTargetDeps={selectedTargetDeps}
              selectedMarket={selectedMarket}
              clickedScenarioIds={clickedScenarioIds}
              setLoadingForecasts={setLoadingForecasts}
              loadingForecasts={loadingForecasts}
            />
          </Tabs.Panel>
        </Tabs>
      </Grid.Col>
    </Grid>
  );
}

export default Dashboard;
